body {
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  min-height: 100vh;
  background: rgb(52, 2, 88);
  background-image: linear-gradient(
      to bottom right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    linear-gradient(
      to top left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 100%
    );

  color: #ddd;
}

.topBar {
  position: sticky;
  top: 0;
  width: 100%;
  background: rgb(0, 0, 0, 0.8);
  color: #fff;
  text-align: right;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.logoWrapper {
  padding-left: 5px;
}

h1 {
  margin: 0;
  font-size: 1.7rem;
}

h1 > span {
  position: relative;
  color: red;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-style: italic;
  font-size: 1.2rem;
  right: 10px;
}

.hamburger {
  width: 25px;
  padding: 5px;
}

.hamburger:hover {
  cursor: pointer;
}

.dropdownMenuVisible {
  position: fixed;
  right: 0;
  display: block;
  width: 30vw;
  transition: 0.5s;
  padding: 10px;
  background: rgb(0, 0, 0, 0.8);
  border-radius: 0 0 0 10px;
  border-left: 3px groove #fff;
  border-bottom: 3px groove #fff;
}

.dropdownMenuVisible li {
  cursor: pointer;
  padding: 5px 0;
}

.dropdownMenuInvisible li {
  cursor: pointer;
  padding: 5px 0;
}

.dropdownMenuInvisible {
  position: fixed;
  right: 0;
  display: block;
  width: 30vw;
  transform: translate3d(100vw, 0, 0);
  transition: 0.5s;
  padding: 10px;
  background: rgb(0, 0, 0, 0.8);
  border-left: 3px groove #fff;
  border-bottom: 3px groove #fff;
}

.topBar ul {
  margin: 0;
  padding: 0;
}

.topBar li {
  list-style: none;
  text-align: center;
  font-size: 1rem;
}

.loginButton {
  margin: 0;
  padding: 5px;
}

.popupOuter {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}

.popup {
  display: block;
  margin: 80px auto;
  padding: 5px 5px 20px;
  width: 80%;
  min-height: 200px;
  background: #000;
  color: #fff;
  border-radius: 10px;
  border-style: groove;
  border-color: #ccc;
  border-width: 2px;
  font-size: 0.8rem;
}

.popup h3 {
  margin: 0;
}

p.alert {
  color: red;
}

.form {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.form input {
  width: 70%;
  display: block;
  margin: auto;
}

.containerWrapper {
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 30px #000;
  /* background-image: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 100%
    ); */
  display: block;
  margin: 20px auto;
  padding: 20px;
  width: 240px;
  border-radius: 20px;
  border: 1px solid #000;
}

.container {
  display: grid;
  grid-template-columns: repeat(20, 1fr);

  background-color: #fff;
  margin: 0 auto;
}

.pixel {
  cursor: crosshair;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0;
  padding: 0;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
}

.pallette {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 240px;
  margin: auto;
}

.activePaint {
  border-style: solid;
  border-width: 1px;
  border-color: yellow;
  margin: 2px;
  border-radius: 30px;
  width: 25px;
  height: 25px;
  background-image: linear-gradient(
      to bottom right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    linear-gradient(
      to top left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 100%
    );
}

.inactivePaint {
  cursor: pointer;
  border-style: groove;
  border-width: 1px;
  border-color: #000;
  border-radius: 30px;
  margin: 2px;
  width: 25px;
  height: 25px;
  background-image: linear-gradient(
      to bottom right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    linear-gradient(
      to top left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 100%
    );
}

.clearButton {
  width: 80%;
  background: #0000;
  color: #fff;
  margin: 5px auto 0;
  border-radius: 5px;
  border: solid 2px #fff;
  cursor: pointer;
}

.clearButton:hover {
  background: #fff;
  color: #000;
}

div.loadListItem {
  color: #fff;
  background: #222;
  width: 80%;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  border-color: #ccc;
  margin: 5px auto;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deleteButton img {
  height: 18px;
  cursor: pointer;
}

.loadFileName {
  cursor: pointer;
  padding-left: 10px;
}

div.overwrite {
  z-index: 1;
}

div.closeButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #0000;
}

div.closeButton {
  background-color: #0000;
  width: 5%;
  padding: 2px 5px;
  color: #fff;
  cursor: pointer;
}

.galleryWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  padding: 0;
  justify-content: center;
}

.orderLinks {
  font-size: 0.68rem;
  display: flex;
  justify-content: center;
}

.orderLinks > div {
  padding: 0 3px;
  cursor: pointer;
}

li.thumbnailWrapper {
  list-style: none;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 150px;
}

.usernameLink {
  cursor: pointer;
}

li.thumbnailWrapper h4 {
  font-size: 0.8rem;
  margin: 5px;
}

li.thumbnailWrapper span {
  text-decoration: underline;
}

li.thumbnailWrapper p {
  margin: 5px;
  font-size: 0.7rem;
}

.thumbnailImageWrapper {
  display: grid;
  grid-template-columns: repeat(20, 5px);
  width: 100px;

  margin: 0 auto;
}

.thumbnailPixel {
  display: block;
  width: 5px;
  height: 5px;
  margin: 0;
  padding: 0;
}

.likeWrapper {
  display: flex;
  justify-content: space-around;
  font-size: 0.8rem;
  align-items: center;
}

.likeButton {
  cursor: pointer;
  background: #fff;
  color: rgb(0, 0, 0);
  padding: 2px 8px;
  border-radius: 5px;
}

.likeButtonLiked {
  cursor: default;
  background: #0a0;
  color: rgb(0, 0, 0);
  padding: 2px 8px;
  border-radius: 5px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: rgb(0, 0, 0, 0.8);
}

.footer span {
  margin: 5px;
  font-size: 0.6rem;
}
